import React, { useEffect, useState, useRef } from "react";
import Layout from "../../layout/Layout";
import { TbLoader3 } from "react-icons/tb";
import { FaEye } from "react-icons/fa";

import { Input, Checkbox, Button } from "@material-tailwind/react";

import { Toaster, toast } from "sonner";
import { useParams } from "react-router-dom";

const OTP = () => {

  const {token} = useParams();

  const [loading, setLoading] = useState(false);
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));
  const [otp, setOtp] = useState('')
  const [email, setEmail] = useState('')

  const inputRefs = useRef([]);

  const handleInput = (e, index) => {
    const value = e.target.value;
    const newOtpValues = [...otpValues];
    if (/^\d$/.test(value)) {
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      newOtpValues[index] = "";
      setOtpValues(newOtpValues);
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
    const emailParam = new URLSearchParams(window.location.search).get('email');
    if(emailParam && token) {
      fetch('https://www.muebleria-villarreal.com/apimv/checkOTPTokenController.php?email=' + emailParam + '&token=' + token)
      .then(response => response.json())
      .then(data => {
          if(data.result == "success") {
              setEmail(emailParam)
              setLoading(false)
          } else {
              // window.location.href = '/login'
              console.log('error: ' + emailParam)
          }
      })
    } else {
      window.location.href = '/login'
    }
  }, [token]);

  // const handleSubmit = () => {
  //   setLoading(true)
  //   console.log(otpValues.join(''))
  // }
  const handleSubmit = () => {
    setLoading(true)
    fetch('https://www.muebleria-villarreal.com/apimv/updateVerificacionOTPController.php?email=' + email + '&otp=' + otpValues.join('') + '&token=' + token)
        .then(response => response.json())
        .then(data => {
            if(data.result == "success") {
              setTimeout(() => {
                window.location.href = '/?success=verificacion'
              }, 2000)
            } else if(data.result == "error_sql_check") {
              setTimeout(() => {
                setLoading(false)
                toast.error('No encontramos tu solicitud. Favor de revisar tu correo nuevamente.', {
                    duration: 5000
                });
              }, 2000)
            } else if(data.result == "error_sql") {
              setTimeout(() => {
                setLoading(false)
                toast.error('No pudimos verificar tu cuenta. Favor de intentar nuevamente', {
                    duration: 5000
                });
              }, 2000)
            } else {
              setTimeout(() => {
                setLoading(false)
                toast.error('Error al enviar la solicitud. Favor de intentar nuevamente.', {
                    duration: 5000
                });
              }, 2000)
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
  }

  return (
    <Layout>
      {loading && (
        <div className="w-full h-lvh bg-black bg-opacity-70 flex justify-center items-center fixed top-0 left-0 z-40">
          <TbLoader3 className="stroke-white animate-spin h-20 w-20" />
        </div>
      )}
      {/* <Toaster richColors closeButton position="top-right"  /> */}
      <section className='w-full pt-20 pb-28 min-h-[60vh] bg-[url("/images/RegBG.jpg")] bg-cover bg-bottom flex justify-center items-center'>
        <div className="container mx-auto flex flex-col lg:flex-row justify-between items-start gap-5 px-4">
          <div className="w-full lg:w-4/12 flex flex-col justify-start items-start gap-4">
            <h1 className="text-xl lg:text-3xl text-dark">
              Verificación de Cuenta.
            </h1>
            <h2 className="text-lg lg:text-xl text-dark">
              Un código de 6 numeros se ha enviado a tu correo electronico.
            </h2>
          </div>
          <div
            className="w-full lg:w-5/12 bg-white p-5 rounded-lg flex flex-col justify-center items-start gap-3"
            style={{
              boxShadow:
                "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)",
            }}
          >
            <h2 className="text-xl text-azul font-negro text-center">
              Código de autenticación
            </h2>
            <p className="text-center text-xs text-azul pb-0 -mb-2 -mt-2">
              Ingresa los 6 digitos enviados a tu correo para validar tu
              información
            </p>
            <div className="w-full grid grid-cols-8 gap-2 pt-7 pb-4">
              <div></div>
              {Array(6).fill(0).map((_, index) => (
                <input
                  key={index}
                  type="text"
                  className="aspect-square text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  maxLength={1}
                  onInput={(e) => handleInput(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
              <div></div>
            </div>
            <Button
              disabled={otpValues.some((value) => value === "")}
              fullWidth
              className="bg-azul"
              onClick={handleSubmit}
            >
              Verificar
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OTP;

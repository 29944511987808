import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import Layout from "../layout/Layout";
import { TbLoader3 } from "react-icons/tb";
import axios from "axios";
import { nb } from "date-fns/locale";

export function Successfull() {

    const [carrito, setCarrito] = useState([]);
    const [direccion, setDireccion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [today, setToday] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [finishFetching, setFinishFetching] = useState(false);
    const [finishOrdenVenta, setFinishOrdenVenta] = useState(false);
    const [dataOrdenVenta, setDataOrdenVenta] = useState(null);
    const [metodoPago, setMetodoPago] = useState(null);
    const [ordenVentaId, setOrdenVentaId] = useState(null);


    useEffect(() => {
        const date = new Date();
        const formattedDate = date.toLocaleDateString('es-MX', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        setToday(formattedDate);
         // Get the URL parameter 'payment_id'
        const queryParams = new URLSearchParams(window.location.search);
        const paymentMethod = queryParams.get('payment_type');
        setPaymentMethod(paymentMethod);
    }, []);
    
    useEffect(() => {
      const cart = localStorage.getItem('cart');
      if (cart) {
        const cartItems = JSON.parse(cart);
        const fetchPromises = cartItems.map(item => {
          return fetch(`https://www.muebleria-villarreal.com/apimv/articulosCarritoController.php?grupo=${item.articulo.grupo}&articulo=${item.articulo}`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              setCarrito(prevState => [...prevState, { articulo: data[0], qty: item.qty }]);
            })
            .catch(error => {
              console.error('There was an error!', error);
              window.location.href = '/carrito';
            });
        });
  
        Promise.all(fetchPromises)
          .then(() => {
            setFinishFetching(true);
          });
      } else {
        window.location.href = '/carrito';
      }
    }, []);

    useEffect(() => {
      if(loading === false){
        localStorage.removeItem('cart');
        localStorage.removeItem('direccionEntregaMV');
        localStorage.removeItem('compra_mv');
        localStorage.removeItem('infoRFC');
      }
    }, [loading]);

    useEffect(() => {
        const dir = localStorage.getItem('direccionEntregaMV');
        if (dir) {
            setDireccion(JSON.parse(dir));
        }
    }, []);

    useEffect(() => {
        updateTotalItems();
        updateTotals();
    }, [carrito]);

    const updateTotalItems = () => {
        const totalQty = carrito.reduce((acc, item) => acc + item.qty, 0);
        setTotalItems(totalQty);
    };

    const updateTotals = () => {
        const totalPrice = carrito.reduce((acc, item) => acc + (item.qty * item.articulo.precio_contado), 0);
        setTotalPrice(totalPrice);
    }

    useEffect(() => {
      if(finishFetching){
        const queryParams = new URLSearchParams(window.location.search);
        const nbResponse = queryParams.get('nbResponse');
        const paymentMethod = queryParams.get('payment_id');
        const paymentType = queryParams.get('payment_type');
        setMetodoPago(queryParams.get('payment_method'));
        const payment_method = queryParams.get('payment_method');
        if(payment_method === "mercado_pago") {
          const totalPrice2 = carrito.reduce((acc, item) => acc + (item.qty * item.articulo.precio_contado), 0);
          localStorage.setItem("compra_mv", JSON.stringify({totalPrice: totalPrice2, formaPago: "Mercado Pago"}));
        }
        if(nbResponse && nbResponse === "Aprobado") {
          const totalPrice2 = carrito.reduce((acc, item) => acc + (item.qty * item.articulo.precio_contado), 0);
          localStorage.setItem("compra_mv", JSON.stringify({totalPrice: totalPrice2, formaPago: "Santander"}));
        }
        if(nbResponse && nbResponse === "Rechazado") {
          window.location.href = '/CarritoCheckOut?error=Rechazado';
        }
        const carritoInfo = JSON.parse(localStorage.getItem('cart'));
        const direccion = JSON.parse(localStorage.getItem('direccionEntregaMV'));
        const compra_mv = JSON.parse(localStorage.getItem('compra_mv'));
        const infoRFC = JSON.parse(localStorage.getItem('infoRFC'));
        const userInfo = JSON.parse(localStorage.getItem('eyJhbGciOiJIUzI1NiJ9')) || [];
        if(nbResponse){
          // axios.post('/api2/generarOrdenVentaController.php', {
          axios.post('https://www.muebleria-villarreal.com/apimv/generarOrdenVentaController.php', {
            carrito: carrito,
            carritoInfo: carritoInfo,
            direccion: direccion,
            compra_mv: compra_mv,
            payment_method: "Santander",
            payment_type: "Santander",
            infoRFC: infoRFC,
            userInfo: userInfo
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response);
            setTimeout(() => {
              compraExitosaCF(response.data.folio, response.data.idCarrito);
            }, 100);
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error("Response error:", error.response.data);
              alert("Error al enviar la información. Favor de comunicarse con nosotros.");
              setLoading(false);
            } else if (error.request) {
              // The request was made but no response was received
              console.error("Request error:", error.request);
              alert("Error al enviar la información. Favor de comunicarse con nosotros.");
              setLoading(false);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error("Error:", error.message);
              alert("Error al enviar la información. Favor de comunicarse con nosotros.");
              setLoading(false);
            }
            console.error("Config:", error.config);
            alert("Error al enviar la información. Favor de comunicarse con nosotros.");
            setLoading(false);
          });
        } else {
          // axios.post('/api2/generarOrdenVentaController.php', {
          axios.post('https://www.muebleria-villarreal.com/apimv/generarOrdenVentaController.php', {
            carrito: carrito,
            carritoInfo: carritoInfo,
            direccion: direccion,
            compra_mv: compra_mv,
            payment_method: paymentMethod,
            payment_type: paymentType,
            infoRFC: infoRFC,
            userInfo: userInfo
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            console.log(response);
            setTimeout(() => {
              if(paymentMethod === "mercado_pago" || paymentMethod === "Transferencia" || paymentMethod === "ticket") {
                compraExitosaSF(response.data.folio);
              } else {
                compraExitosaCF(response.data.folio, response.data.idCarrito);
              }
            }, 100);
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error("Response error:", error.response.data);
              alert("Error al enviar la información. Favor de comunicarse con nosotros.");
              setLoading(false);
            } else if (error.request) {
              // The request was made but no response was received
              console.error("Request error:", error.request);
              alert("Error al enviar la información. Favor de comunicarse con nosotros.");
              setLoading(false);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error("Error:", error.message);
              alert("Error al enviar la información. Favor de comunicarse con nosotros.");
              setLoading(false);
            }
            console.error("Config:", error.config);
            alert("Error al enviar la información. Favor de comunicarse con nosotros.");
            setLoading(false);
          });
        }
      }
    }, [finishFetching]);

    const compraExitosaSF = (ordenVta) => {
      const queryParams = new URLSearchParams(window.location.search);
      const payment_method = queryParams.get('payment_method');
      const direccion = JSON.parse(localStorage.getItem('direccionEntregaMV'));
      const compra_mv = JSON.parse(localStorage.getItem('compra_mv'));
      // axios.post('/api2/successOrdenVtaMail.php', {
      axios.post('https://www.muebleria-villarreal.com/apimv/successOrdenVtaMail.php', {
        correo: direccion.email,
        total: compra_mv.totalPrice,
        folio: ordenVta,
        forma_pago: payment_method,
        nombre: direccion.nombre,
        telefono: direccion.telefono,
        articulos: carrito,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => {
        console.log(response);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        alert("Error al enviar la información. Favor de comunicarse con nosotros.");
        setLoading(false);
      });
    };

    const compraExitosaCF = (ordenVta, idCarritoNum) => {
      const queryParams = new URLSearchParams(window.location.search);
      const payment_method = queryParams.get('payment_method');
      const direccion = JSON.parse(localStorage.getItem('direccionEntregaMV'));
      const compra_mv = JSON.parse(localStorage.getItem('compra_mv'));
      // axios.post('/api2/successOrdenVtaMail.php', {
      axios.post('https://www.muebleria-villarreal.com/apimv/successOrdenVtaMail.php', {
        correo: direccion.email,
        total: compra_mv.totalPrice,
        folio: ordenVta,
        forma_pago: payment_method,
        nombre: direccion.nombre,
        telefono: direccion.telefono,
        articulos: carrito,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        fetch('https://www.muebleria-villarreal.com/apimv/generarFacturaController.php?idCarrito=' + idCarritoNum)
          .then(response => response.json())
          .then(data => {
              console.log(data);
              setLoading(false);
          })
          .catch(error => {
              console.error('Error:', error);
              alert("Error al enviar la información. Favor de comunicarse con nosotros.");
              setLoading(false);
          });
      })
      .catch(error => {
        console.log(error);
        alert("Error al enviar la información. Favor de comunicarse con nosotros.");
        setLoading(false);
      });

    };

    // useEffect(() => {
    //   const queryParams = new URLSearchParams(window.location.search);
    //   const payment_method = queryParams.get('payment_method');
    //   const direccion = JSON.parse(localStorage.getItem('direccionEntregaMV'));
    //   const compra_mv = JSON.parse(localStorage.getItem('compra_mv'));
    //   console.log("Carrito :" + JSON.stringify(carrito));
    //   if(finishOrdenVenta){
    //     axios.post('https://competent-galileo.72-167-48-199.plesk.page/api/compraexitosa', {
    //       correo: direccion.email,
    //       total: compra_mv.totalPrice,
    //       folio: ordenVentaId,
    //       forma_pago: payment_method,
    //       nombre: direccion.nombre,
    //       telefono: direccion.telefono,
    //       articulos: carrito,
    //     }, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //       }
    //     })
    //     .then(response => {
    //       console.log(response);
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    
    //     if(payment_method === "mercado_pago" || payment_method === "Transferencia" || payment_method === "ticket") {
    //       setLoading(false);
    //     } else {
    //       fetch('https://www.muebleria-villarreal.com/apimv/generarFacturaController.php?idCarrito=' + dataOrdenVenta?.idCarrito)
    //       .then(response => response.json())
    //       .then(data => {
    //           console.log(data);
    //           setLoading(false);
    //       })
    //       .catch(error => {
    //           console.error('Error:', error);
    //       });
    //     }
    //   }
    // }, [finishOrdenVenta]);

    

  return (
    <Layout>
    <section className="container mx-auto pt-10 pb-20 px-8">
            {
                loading &&
                    <div className="w-full h-full flex justify-center items-center fixed z-50 bg-white/95 top-0 left-0">
                        <TbLoader3 size={60} className='stroke-azul animate-spin' />
                    </div>
            }
        {
            paymentMethod && paymentMethod == "Transferencia" ?
            <>
                <Typography variant="h4">¡Ya casi es tuyo!</Typography>
                <Typography className="text-gray-600 font-normal text-sm pt-3">
                    Estamos por terminar tu proceso. En breve recibirás un correo electrónico con los detalles de tu compra.
                </Typography>
            </>
            :
            paymentMethod && paymentMethod == "ticket" ?
            <>
                <Typography variant="h4">¡Ya casi es tuyo!</Typography>
                <Typography className="text-gray-600 font-normal text-sm pt-3">
                    Estamos por terminar tu proceso. En breve recibirás un correo electrónico con los detalles de tu compra.
                </Typography>
            </>
            :
            <>
                <Typography variant="h4">¡Gracias por tu compra!</Typography>
                <Typography className="text-gray-600 font-normal text-sm pt-3">
                    Tu pedido ha sido registrado con éxito.
                </Typography>
            </>
        }
      <div className="mt-8 grid lg:gap-x-6 gap-y-6 lg:grid-cols-12 grid-cols-6">
        <div className="col-span-8 space-y-6">
          <Card className="border border-gray-300 !rounded-md shadow-sm">
            <CardBody className="p-4 flex gap-4 flex-col md:flex-row items-center justify-between">
              <div className="flex !justify-between w-full">
                <div>
                  <Typography color="blue-gray" className="!font-semibold">
                    Fecha de compra
                  </Typography>
                  <Typography className="text-gray-600 font-normal">
                  {today}
                  </Typography>
                </div>
                <div>
                  <Typography color="blue-gray" className="!font-semibold">
                    Orden #
                  </Typography>
                  <Typography className="text-gray-600 font-normal">
                    {ordenVentaId}
                  </Typography>
                </div>
              </div>
              <div className="w-full">
              </div>
            </CardBody>
          </Card>
          <Card className="border border-gray-300 !rounded-md shadow-sm">
            <CardBody className="md:px-6 pb-6">
              {carrito && carrito.length && carrito.map((item, index) => (
                <div
                  key={index}
                  className="space-y-10 flex flex-wrap justify-center md:justify-start items-start"
                >
                  <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + item.articulo.departamento + "/" + item.articulo.articulo + ".jpg?quality=auto&format=webp"} className="h-[140px]" alt={item.articulo.descripcion_corta} />
                  <div className="-translate-y-6 space-y-1 md:text-start text-center ml-10">
                    <Typography variant="h6" color="blue-gray">
                      {item.articulo.descripcion_corta}
                    </Typography>
                    <Typography className="font-normal text-gray-600">
                      {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado)} x {item.qty}
                    </Typography>
                    <Typography className="font-bold text-gray-600">
                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.articulo.precio_contado * item.qty)}
                    </Typography>
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>
          <Card className="border border-gray-300 !rounded-md shadow-sm">
            {
                paymentMethod && paymentMethod == "Transferencia" ?
                <CardBody className="md:px-6 pb-6">
                <Typography color="blue-gray" className="!font-semibold pb-4">
                    Instrucciones
                </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    1.- Realiza tu transferencia/depósito a la cuenta que se te proporciona en la imagen anterior y utiliza como concepto tu ID de transacción: {ordenVentaId}
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    2.- Envianos tu comprobante de pago por medio de <a href="http://wa.me/528343110729" target="_blank" className="text-link underline">WhatsApp</a> o por <a href="mailto:ventaswebmvc@gmail.com" target="_blank" className="text-link underline">Correo Electrónico</a>
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-5">
                    3.- Una vez confirmado tu pago, procederemos a enviar tu pedido.
                    </Typography>
                    <img src="https://www.muebleria-villarreal.com/img/pagos.jpg" alt="Pago Transfer" className="w-full max-w-lg" />
                    <Typography className="text-gray-600 font-normal text-sm py-2">
                    <b>Cuenta #:</b> 0021298239
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm">
                    <b>CLABE #:</b> 072810000212982396
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2 pt-10">
                    Si tienes alguna duda o comentario, no dudes en contactarnos al (834) 311 0729.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    *Favor de revisar tu bandeja de correo después de tu compra, ya que te enviaremos tu resumen de compra. (Podría llegar a tu bandeja de SPAN o Correo no deseado)
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    *La venta está sujeta a la disponibilidad de existencias al momento de confirmar el pago.
                    </Typography>
                </CardBody>
                :
                paymentMethod && paymentMethod == "ticket" ?
                <CardBody className="md:px-6 pb-6">
                <Typography color="blue-gray" className="!font-semibold pb-4">
                    Instrucciones
                </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    1.- Revisar tu correo electrónico, en breve recibirás un correo con los detalles de tu compra.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    2.- Acude a la tienda seleccionada mas cercana.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-5">
                    3.- Muestra en caja el ticket que te llegó a tu correo.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-5">
                    4.- Conserva tu comprobante de pago para cualquier aclaración.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-5">
                    5.- El pago se acreditará en 1 a 2 días hábiles.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    *No es necesario que envíes correo o mensaje para informar tu pago.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    *La venta está sujeta a la disponibilidad de existencias al momento de confirmar el pago.
                    </Typography>
                </CardBody>
                :
                <CardBody className="md:px-6 pb-6">
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    Si tienes alguna duda o comentario, no dudes en contactarnos al (834) 311 0729.
                    </Typography>
                    <Typography className="text-gray-600 font-normal text-sm pb-2">
                    *Favor de revisar tu bandeja de correo después de tu compra, ya que te enviaremos tu resumen de compra. (Podría llegar a tu bandeja de SPAN o Correo no deseado)
                    </Typography>
                </CardBody>
            }
        </Card>
        </div>
        <div className="lg:col-span-4 col-span-full space-y-6">
          <Card className="border border-gray-300 !rounded-md shadow-sm">
            <CardBody className="p-4">
              <Typography color="blue-gray" className="!font-semibold">
                Resumen de Compra
              </Typography>
              <div className="my-4 space-y-2">
                  <div className="flex items-center justify-between">
                    <Typography className="text-gray-600 font-normal">
                      Subtotal
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice / 1.16)}
                    </Typography>
                  </div>
                </div>
              <div className="my-4 space-y-2">
                  <div className="flex items-center justify-between">
                    <Typography className="text-gray-600 font-normal">
                      IVA
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice - (totalPrice / 1.16))}
                    </Typography>
                  </div>
                </div>
              <div className="my-4 space-y-2">
                  <div className="flex items-center justify-between">
                    <Typography className="text-gray-600 font-normal">
                    Costo de envío
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(0)}
                    </Typography>
                  </div>
                </div>
              <div className="flex items-center justify-between border-t border-gray-300 pt-4">
                <Typography color="blue-gray" className="!font-semibold">
                Total (IVA incluido)
                </Typography>
                <Typography color="blue-gray" className="!font-semibold">
                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}
                </Typography>
              </div>
            </CardBody>
          </Card>
          <Card className="border border-gray-300 !rounded-md shadow-sm">
            <CardBody className="p-4">
              <div className="flex items-center justify-between">
                <Typography color="blue-gray" className="!font-semibold">
                  Dirección de envío
                </Typography>
              </div>
              {
                direccion &&
                <div className="space-y-2 mt-4">
                    <Typography className="text-gray-600 font-normal">
                    {direccion.nombre} {direccion.apellidoPaterno} {direccion.apellidoMaterno}
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                    {direccion.calle} {direccion.numExt} {direccion.numInt}, {direccion.cp}
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                    {direccion.entreCalle1} y {direccion.entreCalle2}
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                    {direccion.referencias}
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                    {direccion.telefono}
                    </Typography>
                    <Typography className="text-gray-600 font-normal">
                    {direccion.email}
                    </Typography>
                </div>

              }
            </CardBody>
          </Card>
          <button onClick={() => window.location.href = "/"} className='bg-azul text-white py-2 px-5 rounded font-medio w-full hover:bg-azul/80 transition-all'>Regresar a la Tienda</button>
        </div>
      </div>
    </section>
    </Layout>
  );
}

export default Successfull;
import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { TbLoader3 } from "react-icons/tb";
import { FaEye } from "react-icons/fa";

import { Input, Checkbox } from '@material-tailwind/react'

import { Toaster, toast } from 'sonner';

import axios from 'axios';

const Register = () => {

    const [loading, setLoading] = useState(false)

    const [nombre, setNombre] = useState('')
    const [paterno, setPaterno] = useState('')
    const [materno, setMaterno] = useState('')
    const [fechaNacimiento, setFechaNacimiento] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPw, setShowPw] = useState(false)
    const [confirmPw, setConfirmPw] = useState('')
    const [showPw2, setShowPw2] = useState(false)
    const [terminos, setTerminos] = useState(false)
    const [ofertas, setOfertas] = useState(false)
    const [error, setError] = useState(false)
    const [emailError, setEmailError] = useState(true);
    const [phoneError, setPhoneError] = useState(true);
    const [errorFN, setErrorFN] = useState(false);

    const [clienteMV, setClienteMV] = useState(false)

    const formatFechaNacimiento = (fechaNacimiento) => {
        const date = new Date(fechaNacimiento);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('eyJhbGciOiJIUzI1NiJ9'))
        if(user) {
            window.location.href = '/'
        }
    }, []);

    const handleSend = () => {
        setLoading(true)
        fetch('https://www.muebleria-villarreal.com/apimv/registerController.php?nombre=' + nombre + '&paterno=' + paterno + '&materno=' + materno + '&fechaNacimiento=' + formatFechaNacimiento(fechaNacimiento) + '&email=' + email + '&password=' + password)
            .then(response => response.json())
            .then(data => {
                if(data.result == "success") {
                    window.location.href = '/login?register=success'
                } else {
                    setLoading(false)
                    toast.error('El usuario ya existe en la pagina web.', {
                        duration: 5000
                    });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleSendMV = () => {
        setLoading(true)
        fetch('https://www.muebleria-villarreal.com/apimv/registerClienteMVController.php?nombre=' + nombre + '&paterno=' + paterno + '&materno=' + materno + '&fechaNacimiento=' + formatFechaNacimiento(fechaNacimiento) + '&email=' + email + '&password=' + password)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if(data.result == "success") {
                    axios.post('https://www.muebleria-villarreal.com/apimv/sendOTPmail.php', {
                        email: email,
                        token: data.token,
                        otp: data.otp
                        }, {
                          headers: {
                              'Content-Type': 'application/json'
                          }
                      })
                      .then(response => {
                        console.log(response);
                        setLoading(false);
                        toast.success('Se ha enviado correo de confirmacion. No olvides revisar tu carpeta de spam.', {
                            duration: 5000
                        });
                      })
                      .catch(error => {
                        console.log(response);
                        setLoading(false);
                        toast.info('Ocurrio un error, favor de intentar nuevamente.', {
                            duration: 5000
                        })
                      });
                } else if(data.result == "error_existe") {
                    toast.error('El usuario ya existe en la pagina web.', {
                        duration: 5000
                    });
                    setLoading(false)
                } else if(data.result == "error_no_existe_cat_clientes"){
                    toast.error('No existe coincidencias de nombre y correo.', {
                        duration: 5000
                    });
                    setLoading(false)
                } else {
                    toast.error('Ocurrio un error al registrar el usuario.' + data.result, {
                        duration: 5000
                    });
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false)
            });
    };

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
  
    const isPhoneValid = (phone) => {
      const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
      return phoneRegex.test(phone);
    };

    const handleEmailChange = (e) => {
      const email = e.target.value;
      setEmail(email);
      if (!isEmailValid(email)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    };
  
    // const handlePhoneChange = (e) => {
    //   const phone = e.target.value;
    //   if (/^\d*$/.test(phone)) {
    //     setTelefonoCelular(phone);
    //     if (!isPhoneValid(phone)) {
    //       setPhoneError(true);
    //     } else {
    //       setPhoneError(false);
    //     }
    //   }
    // };
  
    const textLength = (text) => {
        return text.length >= 3;
    };
    
    // Get today's date in YYYY-MM-DD format
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()).toISOString().split('T')[0];

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
          age--;
        }
        return age;
      };
    
      const handleDateChange = (e) => {
        const date = e.target.value;
        setFechaNacimiento(date);
        setErrorFN(calculateAge(date) < 18);
    };

  return (
    <Layout>
        {
            loading &&
            <div className='w-full h-lvh bg-black bg-opacity-70 flex justify-center items-center fixed top-0 left-0 z-40'>
                <TbLoader3 className='stroke-white animate-spin h-20 w-20' />
            </div>
        }
        {/* <Toaster richColors closeButton position="top-right"  /> */}
        <section className='w-full pt-20 pb-28 min-h-[60vh] bg-[url("/images/RegBG.jpg")] bg-cover bg-bottom flex justify-center items-center'>
            <div className='container mx-auto flex flex-col lg:flex-row justify-between items-start gap-5 px-4'>
                <div className='w-full lg:w-4/12 flex flex-col justify-start items-start gap-2'>
                    <h1 className='text-xl lg:text-3xl text-dark'>Crea tu cuenta.</h1>
                    <h2 className='text-lg lg:text-2xl text-dark'>Así, todo es más fácil</h2>
                    <p className='text-azul font-medio text-lg lg:text-2xl'>Beneficios</p>
                    <ul className=' list-disc list-inside'>
                        <li>Protege tus datos e información de pago.</li>
                        <li>Compra más rápido en tus siguientes visitas.</li>
                        <li>Guarda distintas direcciones de entrega.</li>
                    </ul>
                </div>
                <div className='w-full lg:w-4/12 bg-white p-5 rounded-lg flex flex-col justify-center items-start gap-3' style={{ boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)" }}>
                    <h2 className='text-xl text-azul font-negro text-center'>Crea tu cuenta</h2>
                    <p className='text-center text-xs text-azul pb-0 -mb-2 -mt-2'>Ingresa estos datos basicos:</p>
                    <Checkbox color='blue' onChange={(e) => setClienteMV(e.target.checked)} label={
                        <span className='text-xs leading-none inline-flex'>Ya soy cleinte de Muebleria Villareal en tiendas fisicas (Si es cliente solo en la pagina web, no aplica)</span>
                    } />
                    {
                        clienteMV ?
                        <>
                            <span className='text-xs italic text-gray-500 -mb-2 -mt-2'>Asegúrese de escribir su nombre como aparece en su identificación (INE)</span>
                            <Input size='lg' label='Nombre *' value={nombre} onChange={(e) => {
                                let value = e.target.value;
                                if(value !== " ") {
                                    setNombre(e.target.value)
                                }
                            }} error={nombre.length < 3 && nombre !== ""} />
                        </>
                        :
                        <Input size='lg' label='Nombre *' value={nombre} onChange={(e) => {
                            let value = e.target.value;
                            if(value !== " ") {
                                setNombre(e.target.value)
                            }
                        }} error={nombre.length < 3 && nombre !== ""} />
                    }
                    <Input size='lg' label='Apellido Paterno *' value={paterno} onChange={(e) => {
                        let value = e.target.value;
                        if(value !== " ") {
                            setPaterno(e.target.value)
                        }
                    }} error={paterno.length < 3 && paterno !== ""} />
                    <Input size='lg' label='Apellido Materno *' value={materno} onChange={(e) => {
                        let value = e.target.value;
                        if(value !== " ") {
                            setMaterno(e.target.value)
                        }
                    }} error={materno.length < 3 && materno !== ""} />
                    <Input size='lg' type='date' label='Fecha de nacimiento *' value={fechaNacimiento} onChange={handleDateChange} max={eighteenYearsAgo} error={errorFN}/>
                    
                    {
                        clienteMV ?
                        <>
                            {
                                error &&
                                <p className='text-xs text-red-600 font-medio'>Ese correo ya existe.</p>
                            }
                            <span className='text-xs italic text-gray-500 -mb-2'>Escriba el correo registrado en tienda</span>
                            <Input size='lg' label='Correo *' value={email} onChange={handleEmailChange} error={emailError && email !== ""} />
                        </>
                        :
                        <>
                            {
                                error &&
                                <p className='text-xs text-red-600 font-medio'>Ese correo ya existe.</p>
                            }
                            <Input size='lg' label='Correo *' value={email} onChange={handleEmailChange} error={emailError && email !== ""} />
                        </>
                    }
                    <Input icon={<FaEye className={`cursor-pointer ${showPw ? "fill-azul" : "fill-darkGris/40"}`} size={20} onClick={() => setShowPw(!showPw)} />} error={password.length <= 6 && password !== ""} size='lg' label='Contraseña *' type={showPw ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                    <Input icon={<FaEye className={`cursor-pointer ${showPw2 ? "fill-azul" : "fill-darkGris/40"}`} size={20} onClick={() => setShowPw2(!showPw2)} />} error={confirmPw.length <= 6 && confirmPw !== "" || confirmPw !== password} size='lg' label='Confirmar Contraseña *' type={showPw2 ? "text" : "password"} value={confirmPw} onChange={(e) => setConfirmPw(e.target.value)} />
                    <div className='w-full flex flex-col justify-start items-start gap-3 checkboxAzulRegister'>
                        <Checkbox onChange={(e) => setTerminos(e.target.checked)} color='blue' label={
                            <span className='text-xs leading-none inline-flex'>He leído y acepto los Términos y condiciones y el Aviso de privacidad.</span>
                        } />
                        <Checkbox onChange={(e) => setOfertas(e.target.checked)} color='blue' label={
                            <span className='text-xs leading-none inline-flex'>Quiero recibir ofertas y promociones en mi correo electrónico.</span>
                        } />
                    </div>
                    {
                        !textLength(nombre) || !textLength(paterno) || !textLength(materno) || !textLength(fechaNacimiento) || errorFN || !email || !isEmailValid(email) || !password || password.length <= 6 || !confirmPw || confirmPw !== password || !terminos ?
                        <button disabled className='bg-azul py-2 px-4 text-white rounded-md mx-auto w-full mt-3 transition-all opacity-40 cursor-not-allowed'>Registrar</button>
                        :
                        <button onClick={clienteMV ? handleSendMV : handleSend} className='bg-azul py-2 px-4 text-white rounded-md mx-auto w-full mt-3 transition-all hover:bg-link'>Registrar</button>
                    }
                    <p className='text-xs pt-3'>¿Ya tienes cuenta? <a href="/login" className='text-link text-xs underline w-full'>Inicia sesión</a></p>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default Register
import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../layout/Layout'
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { Input, Option, Select, Tooltip } from '@material-tailwind/react';
import {
  Chip,
  Button,
  Typography,
  Card,
  CardBody,
  IconButton,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { FaInfoCircle } from "react-icons/fa";
import { TbLoader3 } from 'react-icons/tb';
import { parseISO, format, set } from 'date-fns';
import { es } from 'date-fns/locale';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import axios from 'axios';

import { Toaster, toast } from 'sonner';
import { MdOutlineRateReview } from 'react-icons/md';

const TABLE_HEAD = [
  {
    head: "Folio",
  },
  {
    head: "Fecha",
  },
  {
    head: "Factura",
  },
  {
    head: "Total",
  },
  {
    head: "",
  },
];

const Cliente = () => {

  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);

  //Perfil States
  const [nombre, setNombre] = useState('');
  const [paterno, setPaterno] = useState('');
  const [materno, setMaterno] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [telefonoExists, setTelefonoExists] = useState(true);
  const [uid, setUid] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clienteRegistrado, setClienteRegistrado] = useState(true);
  const [isClienteChecked, setIsClienteChecked] = useState(false);

  //Domicilios States
  const [domicilios, setDomicilios] = useState([]);
  const [domSelected, setDomSelected] = useState(null);
  const [agregarDireccion, setAgregarDireccion] = useState(false);
  const [nombreDireccion, setNombreDireccion] = useState('');
  const [domNombre, setDomNombre] = useState('');
  const [domApellidoP, setDomApellidoP] = useState('');
  const [domApellidoM, setDomApellidoM] = useState('');
  const [domTelefono, setDomTelefono] = useState('');
  const [domCalle, setDomCalle] = useState('');
  const [domNExt, setDomNExt] = useState('');
  const [domNInt, setDomNInt] = useState('');
  const [domCP, setDomCP] = useState('');
  const [domEntreC1, setDomEntreC1] = useState('');
  const [domEntreC2, setDomEntreC2] = useState('');
  const [domReferencias, setDomReferencias] = useState('');
  const [municipioSelected, setMunicipioSelected] = useState(null);
  const [ciudadSelected, setCiudadSelected] = useState(null);
  const [coloniaSelected, setColoniaSelected] = useState(null);
  const [principal, setPrincipal] = useState(null);

  const [domicilioId, setDomicilioId] = useState(null);
    
  const [municipios, setMunicipios] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [colonias, setColonias] = useState([]);

  //Compras States
  const [compras, setCompras] = useState([]);
  const [artCarrito, setArtCarrito] = useState([]);
  const [openCarrito, setOpenCarrito] = useState(false);
  const [indexOrden, setIndexOrden] = useState(0);
  
  const [openFactura, setOpenFactura] = useState(false);
  const [productosFactura, setProductosFactura] = useState([]);

  //Estado de Cuenta states
  const [estadoCuenta, setEstadoCuenta] = useState([]);
  const [openSaldo, setOpenSaldo] = useState(false);
  const [saldo, setSaldo] = useState(0);

  const cardBodyRef = useRef(null);
 
  const handleOpenCarrito = () => {
    setOpenCarrito(!openCarrito)
    setTimeout(() => {
      cardBodyRef.current.scrollTop = 0;
    }, 100);
  };
  
  const handleOpenFactura = () => {
    setOpenFactura(!openFactura)
  };

  const handleOpenSaldo = () => setOpenSaldo(!openSaldo);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('eyJhbGciOiJIUzI1NiJ9'));
    if(user) {
      fetch('https://www.muebleria-villarreal.com/apimv/getUserController.php?id=' + user.id)
      .then(response => response.json())
      .then(data => {
          // console.log(data);
          if(data.result == "successful") {
            setNombre(data.loginInfo.nombre);
            setPaterno(data.loginInfo.paterno);
            setMaterno(data.loginInfo.materno);
            setFechaNacimiento(formatDate(data.loginInfo.fecha_nacimiento));
            setEmail(data.loginInfo.email);
            if(data.loginInfo.telefono == null) {
              setTelefonoExists(false);
            };
            setTelefono(data.loginInfo.telefono);
            setUid(data.loginInfo.id);
            getDirecciones(data.loginInfo.id, data.loginInfo.email);

            if(data.loginInfo.cliente === '160721') {
              setClienteRegistrado(false)
              setLoading(false);
              setIsClienteChecked(true)
            } else {
              fetch('https://www.muebleria-villarreal.com/apimv/getEstadoCuentaController.php?cliente=' + data.loginInfo.cliente)
                .then(response => response.json())
                .then(data => {
                  console.log(data);
                  setEstadoCuenta(data);
                  setClienteRegistrado(true);
                  setLoading(false);
                  setIsClienteChecked(true)
                })
                // .then(data => console.log(data))
                .catch(error => console.error('There was an error!', error));
                setLoading(false);
            }
            
          } else {
              window.location.href = '/'
          }
      })
    } else {
      window.location.href = '/login';
    }
  }, []);

  const getDirecciones = (idUser, emailUser) => {
    fetch('https://www.muebleria-villarreal.com/apimv/getDireccionesUserController.php?id=' + idUser)
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      if(data.direcciones.length > 0) {
        setDomicilios(data.direcciones);
      } else {
        setDomicilios([]);
      }
    })
    fetch('https://www.muebleria-villarreal.com/apimv/getMunicipioController.php')
      .then(response => response.json())
      .then(data => setMunicipios(data))
      .catch(error => console.error('There was an error!', error));

    fetch('https://www.muebleria-villarreal.com/apimv/getUserComprasController.php?id=' + idUser + '&email=' + emailUser)
      .then(response => response.json())
      .then(data => setCompras(data.ordenVtaRow))
      .catch(error => console.error('There was an error!', error));
  };

  const handleViewAllOrders = () => {

    fetch('https://www.muebleria-villarreal.com/apimv/getUserComprasController.php?id=' + uid + '&email=' + email)
      .then(response => response.json())
      .then(data => setCompras(data.ordenVtaRow))
      .catch(error => console.error('There was an error!', error));

  };

  const handleUpdate = () => {
    setLoading(true);
    fetch('https://www.muebleria-villarreal.com/apimv/editUserController.php?nombre=' + nombre + '&paterno=' + paterno + '&materno=' + materno + '&fechaNacimiento=' + formatFechaNacimiento(fechaNacimiento) + '&email=' + email + '&tel=' + telefono)
    .then(response => response.json())
    .then(data => {
        if(data.result == "successful") {
          setLoading(false);
          toast.success('Usuario actualizado correctamente.', {
            duration: 3000
          });

          // Retrieve existing data from localStorage
          let existingData = localStorage.getItem('eyJhbGciOiJIUzI1NiJ9');
          existingData = existingData ? JSON.parse(existingData) : {};

          // Update the object with new information
          const updatedData = { ...existingData, nombre: nombre, nombre_completo: nombre + ' ' + paterno + ' ' + materno, email: email };

          // Store the updated JSON string back to localStorage
          localStorage.setItem('eyJhbGciOiJIUzI1NiJ9', JSON.stringify(updatedData));
        } else {
          toast.error('Hubo un error modificando tu cuenta, favor de intentar mas tarde.', {
            duration: 3000
          });
          setLoading(false);
          console.error('Error: ', data);
        }
    })
    .catch(error => {
        console.error('Error: ', error);
        toast.error('Error: ' + error, {
          duration: 3000
        });
    });
};

  const handleShowDom = (index) => {
    setNombreDireccion(domicilios[index].nombre_direccion);
    setAgregarDireccion(false);
    setDomSelected(index);
    setDomNombre(domicilios[index].nombre_contacto);
    setDomApellidoP(domicilios[index].paterno);
    setDomApellidoM(domicilios[index].materno);
    setDomTelefono(domicilios[index].telefono);
    setDomCalle(domicilios[index].calle);
    setDomNExt(domicilios[index].num_ext);
    setDomNInt(domicilios[index].num_int);
    setDomCP(domicilios[index].cp);
    setDomEntreC1(domicilios[index].entre_calle);
    setDomEntreC2(domicilios[index].y_calle);
    setDomReferencias(domicilios[index].referencias);
    setMunicipioSelected(domicilios[index].municipio);
    setCiudadSelected(domicilios[index].ciudad);
    setColoniaSelected(domicilios[index].colonia);
    setDomicilioId(domicilios[index].id);
    setPrincipal(domicilios[index].es_principal);
  };

  useEffect(() => {
      if (municipioSelected) {
          fetch('https://www.muebleria-villarreal.com/apimv/getCiudadController.php?municipio=' + municipioSelected)
            .then(response => response.json())
            .then(data => setCiudades(data))
            .catch(error => console.error('There was an error!', error));
      }
  }, [municipioSelected]);

  useEffect(() => {
      if (ciudadSelected) {
          fetch('https://www.muebleria-villarreal.com/apimv/getColoniasController.php?municipio=' + municipioSelected + '&ciudad=' + ciudadSelected)
              .then(response => response.json())
              .then(data => setColonias(data))
              .catch(error => console.error('There was an error!', error));
      }
  }, [ciudadSelected, municipioSelected]);

  const newDireccion = () => {
    setDomSelected(999999999);
    setAgregarDireccion(true);
    setNombreDireccion('');
    setDomNombre('');
    setDomApellidoP('');
    setDomApellidoM('');
    setDomTelefono('');
    setDomCalle('');
    setDomNExt('');
    setDomNInt('');
    setDomCP('');
    setDomEntreC1('');
    setDomEntreC2('');
    setDomReferencias('');
    setMunicipioSelected(null);
    setCiudadSelected(null);
    setColoniaSelected(null);
    setCiudades([]);
    setColonias([]);
    setDomicilioId(null);
    setPrincipal(null);
  };

  const handleSeeOrder = (idCarrito, i) => {
    setLoading(true);
    setIndexOrden(i);
    console.log(compras[i])
    fetch('https://www.muebleria-villarreal.com/apimv/getArticulosCarritoController.php?id=' + idCarrito)
    .then(response => response.json())
    .then(data => setArtCarrito(data.carritoRow))
    .then(() => setLoading(false))
    // .then(data => console.log(data))
    .then(() => handleOpenCarrito())
    .catch(error => console.error('There was an error!', error));
  };

// Function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString.replace(' ', 'T'));
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};



const formatFechaNacimiento = (fechaNacimiento) => {
  const date = new Date(fechaNacimiento);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};
    
  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  const exportToPDF = () => {
    const input = document.getElementById('capture');
    html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        pdf.save('estado_de_cuenta.pdf');
    });
};

const handleUpdateDomicilio = () => {
  fetch("https://www.muebleria-villarreal.com/apimv/updateDireccionesUserController.php?id=" + domicilioId + "&userId=" + uid + "&nombreDireccion=" + nombreDireccion + "&nombre=" + domNombre + "&paterno=" + domApellidoP + "&materno=" + domApellidoM + "&telefono=" + domTelefono + "&calle=" + domCalle + "&num_ext=" + domNExt + "&num_int=" + domNInt + "&cp=" + domCP + "&entre_calle=" + domEntreC1 + "&y_calle=" + domEntreC2 + "&referencias=" + domReferencias + "&municipio=" + municipioSelected + "&ciudad=" + ciudadSelected + "&colonia=" + coloniaSelected)
    .then(response => response.json())
    .then(data => {
      if(data.result == "successful") {
        toast.success('Dirección actualizada correctamente.', {
          duration: 3000
        });

      } else {
        toast.error('Hubo un error modificando tu dirección, favor de intentar mas tarde.', {
          duration: 3000
        });
        console.error('Error: ', data);
      }
    })
};

const handleNewDomicilio = () => {
  fetch("https://www.muebleria-villarreal.com/apimv/createDireccionesUserController.php?userId=" + uid + "&nombreDireccion=" + nombreDireccion + "&nombre=" + domNombre + "&paterno=" + domApellidoP + "&materno=" + domApellidoM + "&telefono=" + domTelefono + "&calle=" + domCalle + "&num_ext=" + domNExt + "&num_int=" + domNInt + "&cp=" + domCP + "&entre_calle=" + domEntreC1 + "&y_calle=" + domEntreC2 + "&referencias=" + domReferencias + "&municipio=" + municipioSelected + "&ciudad=" + ciudadSelected + "&colonia=" + coloniaSelected)
    .then(response => response.json())
    .then(data => {
      if(data.result == "successful") {
        toast.success('Dirección creada correctamente.', {
          duration: 3000
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error('Hubo un error creando tu dirección, favor de intentar mas tarde.', {
          duration: 3000
        });
        console.error('Error: ', data);
      }
    })
};

const handleDeleteDomicilio = () => {
  console.log(domicilioId + "?userId=" + uid);
  fetch("https://www.muebleria-villarreal.com/apimv/deleteDireccionesUserController.php?id=" + domicilioId + "?userId=" + uid)
    .then(response => response.json())
    .then(data => {
      if(data.status == "ok") {
        toast.success('Dirección eliminada correctamente.', {
          duration: 3000
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error('Hubo un error eliminando tu dirección, favor de intentar mas tarde.', {
          duration: 3000
        });
        console.error('Error: ', data);
      }
    })
};

const handleAsociarCuenta = () => {
  setLoading(true)
  // axios.post("/api2/asociarClienteMVController.php", {
  axios.post('https://www.muebleria-villarreal.com/apimv/asociarClienteMVController.php', {
    nombre: nombre,
    paterno: paterno,
    materno: materno,
    email: email,
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    console.log(response);
    if(response.data.result == "success") {
      // axios.post("/api2/sendOTPmail.php", {
      axios.post('https://www.muebleria-villarreal.com/apimv/sendOTPmail.php', {
        email: email,
        token: response.data.token,
        otp: response.data.otp
        }, {
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(response => {
        console.log(response);
        setLoading(false);
        toast.success('Se ha enviado correo de confirmacion. No olvides revisar tu carpeta de spam.', {
            duration: 5000
        });
      })
      .catch(error => {
        console.log(response);
        setLoading(false);
        toast.info('Ocurrio un error, favor de intentar nuevamente.', {
            duration: 5000
        })
      });
    } else if(response.data.result == "error_no_existe_cat_clientes"){
        toast.error('No se encuentran coincidencias, favor de revisar tus datos nuevamente.', {
            duration: 5000
        });
        setLoading(false)
    } else {
        toast.error('Ocurrio un error al registrar el usuario.' + response.data.result, {
            duration: 5000
        });
        console.log(response);
        setLoading(false)
    }
  })
  // fetch('https://www.muebleria-villarreal.com/apimv/asociarClienteMVController.php?nombre=' + nombre + '&paterno=' + paterno + '&materno=' + materno + '&email=' + email)
  //     .then(response => response.json())
  //     .then(data => {
  //         console.log(data)
  //         if(data.result == "success") {
  //           toast.success('Usuario asociado correctamente.', {
  //               duration: 5000
  //           });
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 1000);
  //         } else if(data.result == "error_no_existe_cat_clientes"){
  //             toast.error('No se encuentran coincidencias, favor de revisar tus datos nuevamente.', {
  //                 duration: 5000
  //             });
  //             setLoading(false)
  //         } else {
  //             toast.error('Ocurrio un error al registrar el usuario.' + data.result, {
  //                 duration: 5000
  //             });
  //             setLoading(false)
  //         }
  //     })
    .catch(error => {
        console.error('Error:', error);
        setLoading(false)
    });
};

const [facturaWeb, setFacturaWeb] = useState("");

const handleWebArticulosFactura = (pFolio) => {
  const modifiedFolio = pFolio.substring(3);
  fetch('https://www.muebleria-villarreal.com/apimv/getWebArticulosFacturaController.php?folio=' + modifiedFolio)
    .then(response => response.json())
    .then(data => {
      console.log(data.articulos);
      setProductosFactura(data.articulos);
      handleOpenFactura();
      setFacturaWeb(pFolio);
    })
    .catch(error => {
        console.error('Error:', error);
        setLoading(false)
    });
};

const downloadInstructions = () => {
  const input = document.getElementById('instructions-content');
  html2canvas(input, { scale: 2, useCORS: true })
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('Instrucciones.pdf');
    });
};

  return (
    <Layout>
      {/* <Toaster richColors closeButton position="top-right"  /> */}
      {
        loading &&
        <div className='w-full h-lvh bg-white bg-opacity-70 flex justify-center items-center fixed top-0 left-0 z-40'>
            <TbLoader3 className='stroke-azul animate-spin h-20 w-20' />
        </div>
      }
        <Dialog open={openCarrito} size='lg' handler={handleOpenCarrito}>
          <DialogHeader>Pedido #{compras[indexOrden]?.folio_trans}</DialogHeader>
          <DialogBody>
            <Card className="w-full">
              <CardBody ref={cardBodyRef} className='h-full w-full overflow-y-auto max-h-[60svh]'>
                <h2 className='font-bold text-xl'>Dirección de Envío</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pt-2">
                  <div className="flex w-full flex-col justify-start items-start">
                    <p className='text-sm font-semibold'>{compras[indexOrden]?.persona_recibe_reparto}</p>
                    <p className='text-sm'>{compras[indexOrden]?.persona_tel_contacto_reparto}</p>
                    <p className='text-sm'>{compras[indexOrden]?.calle_reparto}</p>
                    <p className='text-sm'>{compras[indexOrden]?.referencias_reparto}</p>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-0 gap-5">
                    <p className='text-sm font-bold lg:text-right'>Subtotal:</p>
                    <p className='text-sm lg:text-left'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(compras[indexOrden]?.subtotal_venta)}</p>
                    <p className='text-sm font-bold lg:text-right'>IVA:</p>
                    <p className='text-sm lg:text-left'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(compras[indexOrden]?.iva_venta)}</p>
                    <p className='text-sm font-bold lg:text-right'>Gastos de envio:</p>
                    <p className='text-sm lg:text-left'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(0)}</p>
                    <p className='text-lg font-bold lg:text-right pt-3 text-azul'>Total de Contado:</p>
                    <p className='text-lg lg:text-left font-bold lg:pt-3 text-azul'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(compras[indexOrden]?.total_venta)}</p>
                  </div>
                </div>
                <table className="w-full min-w-max table-auto text-left mt-4 overflow-x-auto">
                  <thead>
                    <tr>
                      <th className="border-b border-gray-300 !p-4">
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Articulo
                          </Typography>
                        </div>
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Precio
                          </Typography>
                        </div>
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Cantidad
                          </Typography>
                        </div>
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Subtotal
                          </Typography>
                        </div>
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <Typography
                          color="blue-gray"
                          variant="small"
                          className="!font-bold text-center"
                        >
                          Review
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Array.isArray(artCarrito) && artCarrito.map((articulo, index) => {
                        const classes = "!p-4 border-b border-gray-300";
                        return (
                          <tr key={index}>
                            <td className={classes}>
                              <img className='size-24' src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + articulo.articulo.departamento + "/" + articulo.articulo.articulo + ".jpg?quality=auto&format=webp"} />
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="font-normal text-gray-600 max-w-xs"
                              >
                                {articulo.articulo.descripcion_corta}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="!font-normal text-gray-600"
                              >
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(articulo.precio_unitario)}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="!font-normal text-gray-600"
                              >
                                {articulo.cant}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="!font-normal text-gray-600"
                              >
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(articulo.precio_unitario * articulo.cant)}
                              </Typography>
                            </td>
                            <td className={classes} style={{ textAlign: 'center' }}>
                              <IconButton className='bg-azul'>
                                <MdOutlineRateReview className="text-white text-lg" />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                <div className='w-full py-5'>
                  {
                    compras[indexOrden]?.folio_factura == null &&
                    <Card className="border border-gray-300 !rounded-md shadow-sm">
                      <CardBody className="md:px-6 pb-6" id="instructions-content">
                        <Typography color="blue-gray" className="!font-semibold pb-4">
                            Instrucciones
                        </Typography>
                        <Typography className="text-gray-600 font-normal text-sm pb-2">
                        1.- Realiza tu transferencia/depósito a la cuenta que se te proporciona en la imagen anterior y utiliza como concepto tu ID de transacción: {compras[indexOrden]?.folio_trans}
                        </Typography>
                        <Typography className="text-gray-600 font-normal text-sm pb-2">
                        2.- Envianos tu comprobante de pago por medio de <a href="http://wa.me/528343110729" target="_blank" className="text-link underline">WhatsApp</a> o por <a href="mailto:ventaswebmvc@gmail.com" target="_blank" className="text-link underline">Correo Electrónico</a>
                        </Typography>
                        <Typography className="text-gray-600 font-normal text-sm pb-5">
                        3.- Una vez confirmado tu pago, procederemos a enviar tu pedido.
                        </Typography>
                        <img src="/images/pagos.jpg" alt="Pago Transfer" className="w-full max-w-lg mx-auto" />
                        <Typography className="text-gray-600 font-normal text-sm py-2 mx-auto text-center">
                        <b>Cuenta #:</b> 0021298239
                        </Typography>
                        <Typography className="text-gray-600 font-normal text-sm mx-auto text-center">
                        <b>CLABE #:</b> 072810000212982396
                        </Typography>
                        <Typography className="text-gray-600 font-normal text-sm pb-2 pt-10">
                        Si tienes alguna duda o comentario, no dudes en contactarnos al (834) 311 0729.
                        </Typography>
                        <Typography className="text-gray-600 font-normal text-sm pb-2">
                        *Favor de revisar tu bandeja de correo después de tu compra, ya que te enviaremos tu resumen de compra. (Podría llegar a tu bandeja de SPAN o Correo no deseado)
                        </Typography>
                        <Typography className="text-gray-600 font-normal text-sm pb-2">
                        *La venta está sujeta a la disponibilidad de existencias al momento de confirmar el pago.
                        </Typography>
                      </CardBody>
                    </Card>
                  }
                </div>
              </CardBody>
            </Card>
          </DialogBody>
          <DialogFooter className='flex gap-3'>
            <Button className='bg-azul' onClick={handleOpenCarrito}>
              <span>Cerrar</span>
            </Button>
            {
              compras[indexOrden]?.folio_factura == null &&
              <Button className='bg-rojo' onClick={downloadInstructions}>
                <span>Descargar Instrucciones</span>
              </Button>
            }
          </DialogFooter>
        </Dialog>
        <Dialog open={openSaldo} size='md' handler={handleOpenSaldo}>
          <DialogHeader>Abonar a Cuenta</DialogHeader>
          <DialogBody>
            <Card className="w-full">
              <CardBody className='h-full w-full overflow-auto max-h-[60svh] grid grid-cols-2 gap-y-4 gap-x-5'>
                <h2 className='font-bold text-xl'>Saldo Total</h2>
                <h2 className='font-bold text-xl'>
                  {estadoCuenta?.estadoCuenta?.[0] ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(estadoCuenta.estadoCuenta[0]?.saldo) : 'N/A'}
                </h2>
                <h2 className='font-bold text-xl'>Saldo a Pagar</h2>
                <h2 className='font-bold text-xl'>
                  {estadoCuenta?.estadoCuenta?.[0] ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(estadoCuenta.estadoCuenta[0]?.pago_para_no_generar_intereses) : 'N/A'}
                </h2>
                <div className="flex w-full flex-row justify-between items-center gap-3 col-span-2">
                  <h2 className='font-bold text-xl pt-4 w-full'>Cantidad a Pagar</h2>
                  <Input
                    type='number'
                    label='Cantidad a Pagar'
                    className='w-full py-2 px-3 border border-[#cfcfcf] rounded'
                    defaultValue={Number(estadoCuenta?.estadoCuenta?.[0]?.pago_para_no_generar_intereses).toFixed(0) || '0.00'}
                    min={1}
                    max={estadoCuenta?.estadoCuenta?.[0]?.saldo}
                    pattern="\d*" // Restrict input to digits only
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                      setSaldo(parseInt(value, 10) || 0); // Convert to integer and set saldo
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </DialogBody>
          <DialogFooter className='flex flex-row justify-end items-center gap-2'>
            <Button className='bg-azul' onClick={handleOpenSaldo}>
              <span>Cerrar</span>
            </Button>
            <Button className='bg-green-600' disabled onClick={handleOpenSaldo}>
              <span>Abonar</span>
            </Button>
          </DialogFooter>
        </Dialog>

        <Dialog open={openFactura} size='lg' handler={handleOpenFactura}>
          <DialogHeader>Factura {facturaWeb}</DialogHeader>
          <DialogBody>
            <Card className="w-full">
              <CardBody className='h-full w-full overflow-auto max-h-[60svh] gap-y-4 gap-x-5 pt-0'>
                <table className="w-full min-w-max table-auto text-left mt-4 overflow-x-auto">
                  <thead>
                    <tr>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Articulo
                          </Typography>
                        </div>
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Precio
                          </Typography>
                        </div>
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Cantidad
                          </Typography>
                        </div>
                      </th>
                      <th className="border-b border-gray-300 !p-4">
                        <div className="flex items-center">
                          <Typography
                            color="blue-gray"
                            variant="small"
                            className="!font-bold"
                          >
                            Subtotal
                          </Typography>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Array.isArray(productosFactura) && productosFactura.map((articulo, index) => {
                        const classes = "!p-4 border-b border-gray-300";
                        return (
                          <tr key={index}>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="font-normal text-gray-600 max-w-xs"
                              >
                                {articulo.descripcion_corta}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="!font-normal text-gray-600"
                              >
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(articulo.precio)}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="!font-normal text-gray-600"
                              >
                                {
                                  articulo.cantidad.slice(0, -2)
                                }
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                className="!font-normal text-gray-600"
                              >
                                {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(articulo.precio * articulo.cantidad)}
                              </Typography>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </DialogBody>
          <DialogFooter className='flex flex-row justify-end items-center gap-2'>
            <Button className='bg-azul' onClick={handleOpenFactura}>
              <span>Cerrar</span>
            </Button>
            <Button className='bg-green-600' onClick={handleOpenFactura}>
              <span>Abonar</span>
            </Button>
          </DialogFooter>
        </Dialog>
        <section className="w-full px-[5vw] py-10 lg:py-10 relative">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-5">
                <div className="lg:col-span-3 xl:col-span-2 flex flex-col justify-start items-start gap-5 h-fit col-span-12 w-full">
                  <h2 className='font-medio text-xl lg:text-2xl text-left w-full tracking-tight'>Mi Cuenta</h2>
                  <button onClick={() => setTab(0)} className={`py-4 px-5 w-full flex flex-row justify-between items-center rounded transition-all font-bold ${tab == 0 ? "bg-azul text-white" : "hover:bg-azul/90 hover:text-white bg-white"}`} style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.15)" }}>
                    Mi Perfil
                    <FaChevronRight size={12} />
                  </button>
                  <button onClick={() => setTab(1)} className={`py-4 px-5 w-full flex flex-row justify-between items-center rounded transition-all font-bold ${tab == 1 ? "bg-azul text-white" : "hover:bg-azul/90 hover:text-white bg-white"}`} style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.15)" }}>
                    Mis Domicilios
                    <FaChevronRight size={12} />
                  </button>
                  <button onClick={() => setTab(2)} className={`py-4 px-5 w-full flex flex-row justify-between items-center rounded transition-all font-bold ${tab == 2 ? "bg-azul text-white" : "hover:bg-azul/90 hover:text-white bg-white"}`} style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.15)" }}>
                    Mis Compras
                    <FaChevronRight size={12} />
                  </button>
                  <button disabled={!clienteRegistrado} onClick={() => setTab(3)} className={`py-4 px-5 w-full flex flex-row justify-between items-center rounded transition-all font-bold ${tab == 3 ? "bg-azul text-white " :"hover:bg-azul/90 hover:text-white bg-white "} ${!clienteRegistrado ? "opacity-50 cursor-not-allowed" : "opacity-100"}`} style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.15)" }}>
                    Estado de Cuenta
                    <FaChevronRight size={12} />
                  </button>
                </div>
                <div className="col-span-12 lg:col-span-8 xl:col-span-9 flex flex-col justify-start items-start gap-5 bg-white shadow p-5">
                  {
                    tab == 0 &&
                    <>
                      <h2 className='font-medio text-xl lg:text-2xl text-left border-b border-b-[#cfcfcf] pb-2 tracking-tight pr-10'>Mi Perfil</h2>
                      <div className='w-full lg:w-96 flex flex-col justify-start items-start gap-3 pt-5'>
                        <Input disabled={clienteRegistrado} type='text' label='Nombre' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={nombre} onChange={(e) => {
                          setNombre(e.target.value)
                          setDisabled(false)
                          }} />
                        <Input disabled={clienteRegistrado} type='text' label='Apellido Paterno' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={paterno} onChange={(e) => {
                          setPaterno(e.target.value)
                          setDisabled(false)
                          }} />
                        <Input disabled={clienteRegistrado} type='text' label='Apellido Materno' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={materno} onChange={(e) => {
                          setMaterno(e.target.value)
                          setDisabled(false)
                          }} />
                        <Input disabled type='text' label='Correo Electrónico' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={email} onChange={(e) => {
                          setEmail(e.target.value)
                          setDisabled(false)
                          }} />
                        <Input type='tel' label='Teléfono' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={telefono} onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          if (value.length > 0) {
                            setDisabled(false)
                          }
                          if(value.length > 10) {
                            console.log(value.slice(0, 10));
                            setTelefono(value.slice(0, 10));
                          } else {
                            console.log(value.slice(0, 10));
                            setTelefono(value)
                          }
                        }} />
                        <Input type='date' label='Fecha de nacimiento *' max={today} value={fechaNacimiento} onChange={(e) => {
                          setFechaNacimiento(e.target.value)
                          setDisabled(false)
                          }} />
                        <Button disabled={disabled} onClick={handleUpdate} ripple={false} className='w-full py-3 bg-azul text-white mt-3'>Guardar</Button>
                        {
                          isClienteChecked && !clienteRegistrado &&
                          <>
                            <br />
                            <p className='italic text-sm text-gray-700'>Tu cuenta no se encuentra asociada con la base de datos de MV.</p>
                            <Button onClick={handleAsociarCuenta} ripple={false} className='w-full py-3 bg-link text-white'>Asociar Cuenta</Button>
                          </>
                        }
                      </div>
                    </>
                  }
                  {
                    tab == 1 &&
                    <>
                      <h2 className='font-medio text-xl lg:text-2xl text-left border-b border-b-[#cfcfcf] pb-2 tracking-tight pr-10'>Mis Domicilios</h2>
                      <div className='flex flex-col justify-start items-start gap-3 pt-5 w-full'>
                        <div className="flex flex-col lg:flex-row justify-start items-center gap-2 lg:gap-5 w-full lg:w-fit">
                          {
                            domicilios.length > 0 &&
                            <Select label='Selecciona Domicilio' value={domSelected} onChange={(e) => handleShowDom(e)} >
                              {
                                domicilios.map((domicilio, index) => (
                                  <Option key={index} value={index}>{domicilio.nombre_direccion}</Option>
                                ))
                              }
                            </Select>
                          }
                          <Button onClick={newDireccion} ripple={false} className='bg-azul text-white whitespace-nowrap w-full lg:w-fit'><span>Agregar Direccion</span></Button>
                        </div>
                        {
                          domSelected != null &&
                          <div className='pt-5'>
                            <div className="flex w-full justify-start items-center gap-2">
                              <Input type='text' label='Nombre de Domicilio*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={nombreDireccion} onChange={(e) => setNombreDireccion(e.target.value)} />
                            </div>
                          <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-5'>
                            <div className='flex flex-col w-full justify-start items-start gap-3 pt-2'>
                              <h2 className='font-medio text-xl lg:text-2xl text-left tracking-tight'>Quien recibe el pedido</h2>
                              <Input type='text' label='Nombre*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domNombre} onChange={(e) => setDomNombre(e.target.value)} />
                              <Input type='text' label='Apellido Paterno*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domApellidoP} onChange={(e) => setDomApellidoP(e.target.value)} />
                              <Input type='text' label='Apellido Materno*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domApellidoM} onChange={(e) => setDomApellidoM(e.target.value)} />
                              <Input type='text' label='Teléfono*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domTelefono} onChange={(e) => setDomTelefono(e.target.value)} />
                              <p className='italic text-sm text-gray-500 pt-5'>Esta informacion es para contactar al momento de la entrega.</p>
                            </div>
                            <div className='flex flex-col w-full justify-start items-start gap-3 pt-2'>
                              <h2 className='font-medio text-xl lg:text-2xl text-left tracking-tight'>Domicilio de entrega</h2>
                              <Input type='text' label='Calle*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domCalle} onChange={(e) => setDomCalle(e.target.value)} />
                              <Input type='text' label='N. Exterior*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domNExt} onChange={(e) => setDomNExt(e.target.value)} />
                              <Input type='text' label='N. Interior' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domNInt} onChange={(e) => setDomNInt(e.target.value)} />
                              <Input type='text' label='CP*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domCP} onChange={(e) => setDomCP(e.target.value)} />
                              <Input type='text' label='Entre Calle 1*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domEntreC1} onChange={(e) => setDomEntreC1(e.target.value)} />
                              <Input type='text' label='Entre Calle 2*' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domEntreC2} onChange={(e) => setDomEntreC2(e.target.value)} />
                              <Input type='text' label='Referencias' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value={domReferencias} onChange={(e) => setDomReferencias(e.target.value)} />
                              <Tooltip content="Los envíos de nuestros artículos son exclusivamente a Tamaulipas" animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0, y: 25 },
                              }}>
                                <Input disabled type='text' label='Estado' className='w-full py-2 px-3 border border-[#cfcfcf] rounded' value="Tamaulipas" icon={<FaInfoCircle />} />
                              </Tooltip>
                              <Select label='Municipio*' value={municipioSelected} onChange={(e) => {
                                setMunicipioSelected(e)
                                setCiudadSelected(null)
                                setColoniaSelected(null)
                                setColonias([])
                                }}>
                                {
                                  municipios && municipios.length && municipios
                                      .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                                      .map((municipio, index) => (
                                          <Option key={index} value={municipio.municipio}>{municipio.descripcion}</Option>
                                      ))
                                }
                              </Select>
                              {
                                ciudades && ciudades.length > 0 && municipioSelected !== "" &&
                                <Select label="Ciudad*" value={ciudadSelected} onChange={(e) => {
                                  setCiudadSelected(e)
                                  setColoniaSelected(null)
                                  setColonias([])
                                }}>
                                  {
                                    ciudades && ciudades.length && ciudades
                                      .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                                      .map((ciudad, index) => (
                                        <Option key={index} value={ciudad.ciudad}>{ciudad.descripcion}</Option>
                                    ))
                                  }
                                </Select>
                              }
                              {
                                colonias && colonias.length > 0 && ciudadSelected !== "" && municipioSelected !== "" &&
                                <Select label='Colonia*' value={coloniaSelected} onChange={(e) => setColoniaSelected(e)}>
                                  {
                                      colonias && colonias.length && ciudadSelected !== "" && colonias
                                      .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                                      .map((colonia, index) => (
                                          <Option key={index} value={colonia.colonia}>{colonia.descripcion}</Option>
                                      ))
                                  }
                                </Select>
                              }
                              
                                {
                                  agregarDireccion ?
                                  <div className="flex flex-row justify-end items-center gap-4 w-full">
                                    <Button onClick={handleNewDomicilio} ripple={false} className='bg-link text-white whitespace-nowrap'><span>Agregar Direccion</span></Button>
                                  </div>
                                  :
                                  <div className="flex flex-row justify-end items-center gap-4 w-full">
                                    <Button onClick={handleDeleteDomicilio} ripple={false} className='bg-rojo text-white whitespace-nowrap'><span>Eliminar</span></Button>
                                    <Button onClick={handleUpdateDomicilio} ripple={false} className='bg-green-700 text-white whitespace-nowrap'><span>Guardar Direccion</span></Button>
                                  </div>
                                }
                              <p className='text-sm text-rojo pt-5 font-medio'>Los campos con * son necesarios.</p>
                              <p className='italic text-sm text-gray-500 -mt-1'>Si quieres modificar una direccion de reparto solo cambia los campos necesarios y da click en "guardar dirección" y listo, tu direccion esta actualizada.</p>
                            </div>
                          </div>
                        </div>
                        }
                      </div>
                    </>
                  }
                  {
                    tab == 2 &&
                    <>
                      <div className='w-full flex flex-row justify-between items-end gap-5'>
                        <h2 className='font-medio text-xl lg:text-2xl text-left border-b border-b-[#cfcfcf] pb-2 tracking-tight pr-10'>Mis Compras</h2>
                        <div className='flex gap-2'>
                          <Button onClick={() => {
                            const pendientes = compras.filter(compra => compra.estado_trans !== 'A');
                            setCompras(pendientes);
                          }} className='bg-azul text-white whitespace-nowrap'>Ver Pendientes</Button>
                          <Button onClick={handleViewAllOrders} className='bg-azul text-white whitespace-nowrap'>Ver Todos</Button>

                        </div>
                      </div>
                      <div className='flex flex-col justify-start items-start gap-3 pt-5 w-full'>
                      <Card className="h-full w-full over">
                        <CardBody className="!px-0 py-0 overflow-x-auto">
                          <table className="w-full min-w-max table-auto text-left">
                            <thead>
                              <tr>
                                {TABLE_HEAD.map(({ head, icon }) => (
                                  <th
                                    key={head}
                                    className="border-b border-gray-300 !p-4"
                                  >
                                    <div className="flex items-center">
                                      {icon}
                                      <Typography
                                        color="blue-gray"
                                        variant="small"
                                        className="!font-bold"
                                      >
                                        {head}
                                      </Typography>
                                    </div>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {compras && compras.map(
                                (compra, index) => {
                                  const classes = "!p-4 border-b border-gray-300";
                                  return (
                                    <tr key={index}>
                                      <td className={classes}>
                                        <Typography
                                          variant="small"
                                          className="font-normal text-gray-600"
                                        >
                                          {compra.folio_trans}
                                        </Typography>
                                      </td>
                                      <td className={classes}>
                                        <Typography
                                          variant="small"
                                          className="!font-normal text-gray-600"
                                        >

                                        {format(parseISO(compra.fecha_venta), "d 'de' MMMM 'de' yyyy", { locale: es })}
                                        </Typography>
                                      </td>
                                      <td className={classes}>
                                        <div className="w-max">
                                        <Chip
                                          variant="ghost"
                                          value={compra.folio_factura !== null ? "FW-" + compra.folio_factura : "Pendiente"}
                                          color={
                                            compra.folio_factura !== null
                                              ? "green"
                                              : "blue"
                                          }
                                        />
                                        </div>
                                      </td>
                                      <td className={classes}>
                                        <Typography
                                          variant="small"
                                          className="!font-normal text-gray-600"
                                        >
                                          {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(compra.total_venta)}
                                        </Typography>
                                      </td>
                                      <td className="border-b border-gray-300 text-right pr-6">
                                        <IconButton
                                          className='bg-azul'
                                          rounded={true}
                                          onClick={() => handleSeeOrder(compra.id_carrito, index)}
                                        >
                                          <FaEye size={16}/>
                                        </IconButton>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </CardBody>
                      </Card>
                      </div>
                    </>
                  }
                  {
                    tab == 3 && estadoCuenta && isClienteChecked && clienteRegistrado &&
                    <div className="w-full flex flex-col justify-start items-start gap-2 mb-3 p-0 md:p-3" id="capture">
                      <div className="w-full flex flex-col md:flex-row justify-between items-start gap-2">
                        <div className="flex flex-col justify-start items-start gap-2">
                          <img src="/images/logo_primario.png" alt="MV Logo" className="w-[90%] max-w-[300px]" />
                          <div className="flex flex-col justify-start items-start gap-0">
                            <span className="fs-md-6 font-bold uppercase text-[14px]">NUMERO DE CUENTA: {estadoCuenta?.estadoCuenta?.[0]?.cliente ?? ''}</span>
                            <span className="fs-md-6 font-bold uppercase text-[14px]">{estadoCuenta?.estadoCuenta?.[0]?.nombre ?? ''}</span>
                            <span className="fs-md-6 text-[14px]">{estadoCuenta?.estadoCuenta?.[0]?.direccion ?? ''}</span>
                            <span className="fs-md-6 text-[14px]">{estadoCuenta?.estadoCuenta?.[0]?.colonia ?? ''} {estadoCuenta?.estadoCuenta?.[0]?.ciudad ?? ''} {estadoCuenta?.estadoCuenta?.[0]?.cp ?? ''}</span>
                          </div>
                        </div>
                        <div className="table-responsive w-full max-w-[400px]">
                          <table className="table">
                            <tbody>
                              <tr className="bg-[#003987] text-white">
                                <td className="w-full">SALDO ACTUAL</td>
                                <td>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(estadoCuenta?.estadoCuenta?.[0]?.saldo ?? 0)}</td>
                              </tr>
                              <tr>
                                <td className="w-full uppercase text-[14px]">Saldo Vencido</td>
                                <td>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(estadoCuenta?.estadoCuenta?.[0]?.saldo_vencido ?? 0)}</td>
                              </tr>
                              <tr>
                                <td className="w-full uppercase text-[14px]">Intereses Moratorios</td>
                                <td className="text-[14px]">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(estadoCuenta?.estadoCuenta?.[0]?.intereses_moratorios ?? 0)}</td>
                              </tr>
                              <tr className="bg-[#003987] text-white">
                                <td className="w-full uppercase">SALDO A PAGAR</td>
                                <td>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(estadoCuenta?.estadoCuenta?.[0]?.pago_para_no_generar_intereses ?? 0)}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="grid grid-cols-2 gap-1 w-full py-2">
                            <p className="uppercase text-[14px] mb-0">CORTE: </p>
                            <p className="text-[14px] font-bold uppercase mb-0 text-right">{new Date(estadoCuenta?.estadoCuenta?.[0]?.fecha_proximo_pago ?? '').toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex flex-col md:flex-row justify-between items-start gap-0 pt-2">
                        <div className="w-full max-w-[400px] flex flex-col justify-start items-start gap-2">
                          <div className="w-full flex flex-row justify-center items-center gap-3 bg-[#003987] p-2">
                            <p className="uppercase text-[14px] text-white mb-0">MONEDERO ELECTRÓNICO: </p>
                            <p className="text-[14px] font-bold text-white mb-0">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(estadoCuenta?.estadoCuenta?.[0]?.saldo_monedero ?? 0)}</p>
                          </div>
                        </div>
                        <span className="font-libre-barcode-39 text-[100px] leading-[0.7] mt-10 lg:mt-[20px] md:mt-0 mx-auto lg:mx-0">{estadoCuenta?.estadoCuenta?.[0]?.cliente ?? ''}</span>
                      </div>
                      <div className="w-full py-2">
                        <p className="bg-white text-[#003987] w-full text-center py-2 rounded-top mb-0 font-bold text-xl">ESTADO DE CUENTA</p>
                        <div className="table-responsive w-full overflow-x-auto lg:overflow-hidden">
                          <table className="table border w-full table-auto">
                            <thead>
                              <tr className="bg-[#003987]">
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Fecha Compra</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">N. Factura</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Letra</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Fecha Vencimiento</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Saldo Actual</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Saldo Vencido</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Intereses</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Mensualidad</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Saldo a Pagar</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto">Saldo Despues Pago</th>
                                <th className="text-[10px] text-white font-bold uppercase text-center align-middle py-2 w-48 lg:w-auto"></th>
                              </tr>
                            </thead>
                            <tbody className="border-t-0">
                              {estadoCuenta?.estadoCuentaFact?.map((historial, index) => (
                                <tr key={index}>
                                  <td className="text-xs text-center align-middle p-2">{new Date(historial.fecha_compra).toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })}</td>
                                  <td className="text-xs text-center align-middle p-2"><Button onClick={() => {
                                    handleWebArticulosFactura(historial.no_factura)
                                  }} className='bg-azul'>{historial.no_factura}</Button></td>
                                  <td className="text-xs text-center align-middle p-2">{historial.letra}</td>
                                  <td className="text-xs text-center align-middle p-2">{new Date(historial.fecha_vencimiento).toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })}</td>
                                  <td className="text-xs text-center align-middle p-2">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(historial.saldo_actual ?? 0)}</td>
                                  <td className="text-xs text-center align-middle p-2">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(historial.saldo_vencido ?? 0)}</td>
                                  <td className="text-xs text-center align-middle p-2">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(historial.intereses ?? 0)}</td>
                                  <td className="text-xs text-center align-middle p-2">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(historial.mensualidad ?? 0)}</td>
                                  <td className="text-xs text-center align-middle p-2">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(historial.pago_no_generar_intereses ?? 0)}</td>
                                  <td className="text-xs text-center align-middle p-2">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(historial.saldo_despues_del_pago ?? 0)}</td>
                                  <td className="text-xs text-center align-middle p-2">
                                    <Button onClick={handleOpenSaldo} className='bg-link text-xs'>Pagar</Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <img src="https://www.muebleria-villarreal.com/img/Octubre2024/oct_Mesa%20de%20trabajo%20248@1.5x.png" alt="Banner" className="w-full rounded" />
                      <div className="flex w-full justify-end items-center gap-3 pt-10">
                        <Button onClick={exportToPDF} className='bg-azul'>Descargar</Button>
                        {/* {
                          estadoCuenta?.estadoCuenta?.[0]?.saldo > 0 ?
                          <Button onClick={handleOpenSaldo} className='bg-rojo'>Pagar Saldo</Button>
                          :
                          <Button disabled className='bg-rojo'>Pagar Saldo</Button>
                        } */}
                      </div>
                    </div>
                  }
              </div>
            </div>
        </section>
    </Layout>
  )
}

export default Cliente
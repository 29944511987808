import React, {useState, useEffect} from 'react'
import Layout from '../../layout/Layout'
import { TbLoader3 } from "react-icons/tb";

import { Input } from '@material-tailwind/react'
import { Alert } from "@material-tailwind/react";

import { Toaster, toast } from 'sonner';
import axios from 'axios';

const PasswordReset = () => {
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(true);

  const handleSubmit = () => {
    setLoading(true)
    fetch('https://www.muebleria-villarreal.com/apimv/reestablecerPasswordController.php?email=' + email)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data.result == "success") {
              // axios.post('/api2/reestablecerPasswordMail.php', {
              axios.post('https://www.muebleria-villarreal.com/apimv/reestablecerPasswordMail.php', {
                email: email,
                token: data.token,
                }, {
                  headers: {
                      'Content-Type': 'application/json'
                  }
              })
              .then(response => {
                console.log(response);
                setLoading(false);
                toast.info('¡Recordatorio de contraseña enviado! No olvides revisar tu carpeta de spam.', {
                    duration: 5000
                })
              })
              .catch(error => {
                console.log(response);
                setLoading(false);
                toast.info('¡Recordatorio de contraseña enviado! No olvides revisar tu carpeta de spam.', {
                    duration: 5000
                })
              });
            } else if(data.result == "error_no_email") {
              setTimeout(() => {
                setLoading(false)
                toast.error('Falta ingresar un email correcto.', {
                    duration: 5000
                });
              }, 2000)
            } else if(data.result == "error_email_not_found") {
              setTimeout(() => {
                setLoading(false)
                toast.error('No encontramos usuario con este correo.', {
                    duration: 5000
                });
              }, 2000)
            } else {
              setTimeout(() => {
                setLoading(false)
                toast.error('Error al enviar la solicitud. Favor de intentar nuevamente.', {
                    duration: 5000
                });
              }, 2000)
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
  }

  const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    if (!isEmailValid(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  return (
    <Layout>
        <section className='w-full pt-20 pb-28 min-h-[60vh] bg-[url("/images/Resetpass.jpg")] bg-cover bg-center flex justify-center items-center'>
          {/* <Toaster richColors closeButton position="top-right"  /> */}
            {
                loading &&
                <div className='w-full h-lvh bg-black bg-opacity-70 flex justify-center items-center fixed top-0 left-0 z-40'>
                    <TbLoader3 className='stroke-white animate-spin h-20 w-20' />
                </div>
            }
            <div className='container mx-auto flex flex-col lg:flex-row justify-between items-start gap-5 px-4'>
                <div className='w-full lg:w-4/12 flex flex-col justify-start items-start gap-2'>
                </div>
                <div className='w-full lg:w-4/12 bg-white p-5 rounded-lg flex flex-col justify-center items-center gap-2' style={{ boxShadow: "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)" }}>
                    <h2 className='text-xl text-azul font-negro text-center'>Cambia tu contraseña</h2>
                    <p className='text-center text-xs text-azul pb-5'>Solo ingresa tu correo electrónico. Es normal que la olvidemos, por suerte puedes cambiarla.</p>
                    
                    <Input size='lg' label='Correo *' type='email' value={email} onChange={handleEmailChange} error={emailError && email !== ""} />
                    <button onClick={handleSubmit} className={`bg-azul py-2 px-4 text-white rounded-md mx-auto w-full mt-3 transition-all ${emailError ? "opacity-50 cursor-not-allowed" : "opacity-100 hover:bg-link"}`} disabled={emailError}>Cambiar Contraseña</button>
                    <p className='text-xs pt-3'>¿Ya tienes cuenta? <a href="/login" className='text-link text-xs underline w-full'>Inicia sesión</a></p>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default PasswordReset
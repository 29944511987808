import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Toaster, toast } from "sonner";

const Home = () => {

    const [productos, setProductos] = useState({})
    const [ready, setReady] = useState(false)

    useEffect(() => {
        fetch('https://www.muebleria-villarreal.com/apimv/indexController.php')
            .then(response => response.json())
            .then(data => {
                setProductos(data)
                setReady(true)
            })
    }, [])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const successParam = urlParams.get('success');
        if (successParam) {
            toast.success('¡Cuenta asociada correctamente!.', {
                duration: 5000
            });
            urlParams.delete('success');
            window.history.replaceState(null, '', `${window.location.pathname}`);
        }
    }, []);


  return (
    <Layout>
        <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/topbar.png" alt="Banner Noviembre" className='w-full object-cover' />
        <Splide options={{
            perPage: 1,
            gap: 0,
            perMove: 1,
            pagination: true,
            arrows: true,
            type: 'loop',
            autoplay: true,
        }}>
            <SplideSlide>
                <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/banner.png" alt="" className='w-full object-cover object-center' />
            </SplideSlide>
            {/* <SplideSlide>
                <a href="https://issuu.com/mvc35/docs/folleto_vic_ago_24?fbclid=IwY2xjawEbN_JleHRuA2FlbQIxMAABHRW2hZ-P15kM27qDpVNocx9A1kK0fkdSXwBK3Hz-V_rnZtJpBTSfgn8raA_aem_wuQObcDzeeoo_UOWE5kg_A" target='_blank'><img src="/images/homeHero.png" alt="" className='w-full object-cover object-center' /></a>
            </SplideSlide> */}
        </Splide>
        <div className="w-full px-[5vw] py-10 lg:py-20 hidden lg:flex flex-row justify-center items-center gap-5">
            <a href="/Departamento/4/91" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-fit'>
                <img src='/images/aireacondicionado.png' className=' mix-blend-darken w-32' />
                <h3 className='text-center font-medio text-xl '>Minisplit</h3>
            </a>
            <a href="/Departamento/10/206" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-fit'>
                <img src='/images/Tablet.png' className=' mix-blend-darken w-32' />
                <h3 className='text-center font-medio text-xl '>Tablet</h3>
            </a>
            <a href="/Departamento/4/108" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-fit'>
                <img src='/images/Refrigerador.png' className=' mix-blend-darken w-32' />
                <h3 className='text-center font-medio text-xl '>Refrigeradores</h3>
            </a>
            <a href="/Departamento/4/103" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-fit'>
                <img src='/images/Lavadora.png' className=' mix-blend-darken w-32' />
                <h3 className='text-center font-medio text-xl '>Lavadoras</h3>
            </a>
            <a href="/Departamento/16/163" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-fit'>
                <img src='/images/moto.png' className=' mix-blend-darken w-32' />
                <h3 className='text-center font-medio text-xl '>Motocicletas</h3>
            </a>
            <a href="/Departamento/4/99" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-fit'>
                <img src='/images/Estufa.png' className=' mix-blend-darken w-32' />
                <h3 className='text-center font-medio text-xl '>Estufas</h3>
            </a>
        </div>
        <div className="w-full px-[5vw] py-10 lg:py-20 block lg:hidden">
            <Splide id="splideDeptosHome" options={{
                perPage: 2,
                perMove: 1,
                arrows: false,
                gap: '1rem',
                type: 'loop',
            }}>
                <SplideSlide>
                    <a href="/Departamento/4/91" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-full'>
                        <img src='/images/aireacondicionado.png' className=' mix-blend-darken w-32' />
                        <h3 className='text-center font-medio text-xl '>Minisplit</h3>
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/10/206" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-full'>
                        <img src='/images/Tablet.png' className=' mix-blend-darken w-32' />
                        <h3 className='text-center font-medio text-xl '>Tablet</h3>
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/4/108" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-full'>
                        <img src='/images/Refrigerador.png' className=' mix-blend-darken w-32' />
                        <h3 className='text-center font-medio text-xl '>Refrigeradores</h3>
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/4/103" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-full'>
                        <img src='/images/Lavadora.png' className=' mix-blend-darken w-32' />
                        <h3 className='text-center font-medio text-xl '>Lavadoras</h3>
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/16/163" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-full'>
                        <img src='/images/moto.png' className=' mix-blend-darken w-32' />
                        <h3 className='text-center font-medio text-xl '>Motocicletas</h3>
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/4/99" className='flex flex-col justify-center items-center gap-2 bg-[#F4F6FA] rounded-md p-5 w-full'>
                        <img src='/images/Estufa.png' className=' mix-blend-darken w-32' />
                        <h3 className='text-center font-medio text-xl '>Estufas</h3>
                    </a>
                </SplideSlide>
            </Splide>
        </div>
        <section className='w-full px-[5vw] hidden lg:grid grid-cols-2 xl:grid-cols-4 gap-3 pb-10 lg:pb-20'>
            <a href="/Departamento/15/132" className='rounded-xl p-2 w-full'>
                <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/colchones.png" alt="" className='w-full' />
            </a>
            <a href="/Departamento/17/206" className='rounded-xl p-2 w-full'>
                <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/tablets.png" alt="" className='w-full' />
            </a>
            <a href="/Departamento/2/22" className='rounded-xl p-2 w-full'>
                <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/comedores.png" alt="" className='w-full' />
            </a>
            <a href="/Departamento/1/55" className='rounded-xl p-2 w-full'>
                <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/salas.png" alt="" className='w-full' />
            </a>
        </section>
        <section className='w-full pb-10 lg:hidden'>
            <Splide options={{
                type: 'loop',
                focus: 'center',
                gap: '1rem',
                padding: "2rem",
                pagination: false,
                perPage: 2,
                arrows: false,
                autoWidth: false,
                breakpoints: {
                    640: {
                        perPage: 1,
                        arrows: false,
                        autoWidth: false,
                    },
                }
            }}>
                <SplideSlide>
                    <a href="/Departamento/15/132" className='rounded-xl w-full'>
                        <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/colchones.png" alt="" className='w-full ' />
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/17/206" className=' rounded-xl w-full'>
                        <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/tablets.png" alt="" className='w-full ' />
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/2/22" className=' rounded-xl w-full'>
                        <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/comedores.png" alt="" className='w-full ' />
                    </a>
                </SplideSlide>
                <SplideSlide>
                    <a href="/Departamento/1/55" className=' rounded-xl w-full'>
                        <img src="https://www.muebleria-villarreal.com/img/Diciembre2024/salas.png" alt="" className='w-full ' />
                    </a>
                </SplideSlide>
            </Splide>
        </section>
        <a href="/Departamento/3/86?page=1&arpge=24&marca=Samsung" className='w-full'><img src="https://www.muebleria-villarreal.com/img/Diciembre2024/samsung.png" alt="" className='w-full' /></a>
        <section className='w-full px-[5vw] py-10 lg:py-20'>
            <h2 className='font-negro text-2xl lg:text-3xl text-left'>Productos Populares</h2>
            <div className="hidden lg:grid xl:grid-cols-5 grid-cols-3 gap-4">
                {
                    Array.isArray(productos) && productos.map((producto, index) => {
                        return (
                            <a key={producto.articulo} href={"/Articulo/" + producto.articulo + "/" + producto.grupo} className='p-5 w-full flex flex-col justify-start items-start'>
                                <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + producto.departamento + "/" + producto.articulo + ".jpg?quality=auto&format=webp"} alt="" className='w-full aspect-square object-cover object-center rounded-lg' />
                                <span className='text-sm text-darkGris'>{producto.nom_grupo}</span>
                                <h5 className='font-negro leading-tight pt-2 text-lg'>{producto.descripcion_corta}</h5>
                                <div className="flex justify-start items-end gap-2 pt-3">
                                    <span className='text-gris text-sm cross line-through font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.precio_lista)}</span>
                                    <span className='text-rojo text-xl cross font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.precio_contado)}</span>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
            <div className="w-full py-10 lg:py-20 block lg:hidden">
                {
                    ready && 
                    <Splide id="splidePopularesHome" options={{
                        perPage: 2,
                        breakpoints: {
                            640: {
                                type: 'loop',
                                focus: 'center',
                                gap: '1rem',
                                perPage: 1,
                                arrows: false,
                                autoWidth: false,
                                
                            },
                        }
                    }}>
                        {
                            Array.isArray(productos) && productos.map((producto, index) => (
                                    <SplideSlide key={index}>
                                        <a href={"/Articulo/" + producto.articulo + "/" + producto.grupo} className='p-5 w-full flex flex-col justify-start items-start'>
                                            <img src={"https://s3.us-east-2.amazonaws.com/cdn.muebleriavillarreal/mvcweb/images/articulos_img/" + producto.departamento + "/" + producto.articulo + ".jpg?quality=auto&format=webp"} alt="" className='w-full aspect-square object-cover object-center rounded-lg' />
                                            <span className='text-sm text-darkGris'>{producto.nom_grupo}</span>
                                            <h5 className='font-negro leading-tight pt-2 text-lg'>{producto.descripcion_corta}</h5>
                                            <div className="flex flex-col justify-start items-start gap-0 pt-3 w-full">
                                                <span className='text-gris text-sm cross line-through font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.precio_lista)}</span>
                                                <span className='text-rojo lg:text-xl cross font-medio'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.precio_contado)}</span>
                                            </div>
                                        </a>
                                    </SplideSlide>
                                )
                            )
                        }
                    </Splide>
                }
            </div>
        </section>
        <a href="http://wa.me/528343110729" target='_blank' className='w-full'>
            <img src="/images/lastone.png" alt="" className='w-full' />
        </a>
        <section className='py-10 lg:py-20 container mx-auto flex flex-col lg:flex-row justify-between items-center gap-5 px-5'>
            <div className='flex flex-row lg:flex-col justify-start items-start lg:items-center gap-2 flex-1'>
                <img src="/images/enviogratis.png" alt="" className='size-12' />
                <div className='w-full flex flex-col justify-center items-start lg:items-center'>
                    <h4 className='text-center font-negro text-azul text-xl'>Envio Gratis</h4>
                    <p className='text-center text-base'>Aplican restricciones</p>
                </div>
            </div>
            <div className='flex flex-row lg:flex-col justify-start items-start lg:items-center gap-2 flex-1'>
                <img src="/images/comprasegura.png" alt="" className='size-12' />
                <div className='w-full flex flex-col justify-center items-start lg:items-center'>
                    <h4 className='text-center font-negro text-azul text-xl'>Compra fácil y segura</h4>
                    <p className='text-center text-base'>Toda tu información protegida</p>
                </div>
            </div>
            <div className='flex flex-row lg:flex-col justify-start items-start lg:items-center gap-2 flex-1'>
                <img src="/images/garantia.png" alt="" className='size-12' />
                <div className='w-full flex flex-col justify-center items-start lg:items-center'>
                    <h4 className='text-center font-negro text-azul text-xl'>Hasta 1 año de garantía</h4>
                    <p className='text-center text-base'>En productos seleccionados</p>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default Home